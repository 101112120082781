<template>
    <div class="container content">
        <top-navigation-bar
            title="Confidence Intervals"
            right-nav="home"
        />
        <div class="d-flex route-btn-view pb-5">
            <button
                v-for="(button,key) in routes"
                :key="key"
                class="route-btn lg double"
                @click="navigate(button)"
            >
                {{ button.title }}
            </button>
        </div>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import localStore from '@/store/localStore.js'
import _ from 'lodash'

export default {
    name: 'Confidence',
    components: {
        TopNavigationBar
    },
    data () {
        return {
            allData: {},
            routes: [
                { title: 'One Sample', name: 'one' },
                { title: 'Two Samples', name: 'two' },
            ],
        }
    },
    beforeMount () {
        var store = localStore.getStore('confidence-data')
        if (store) {
            _.assign(this, { allData: store })
        }
    },
    methods: {
        navigate (choice) {
            var { allData } = this
            _.set(this.allData, 'choice', choice)
            localStore.setStoreItem('confidence-data', allData)
            this.$router.push({ name: 'confidence/ci-choice-formula' })
        },
    }
}
</script>
